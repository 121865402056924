import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHtmlToPaper from "vue-html-to-paper";
//import VueNotification from "@kugatsu/vuenotification";
import Notifications from "vue-notification";
import VueShowdown from "vue-showdown";


// the second parameter of Vue.use() is optional
Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: "github",
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: false
  }
});

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import "@fortawesome/fontawesome-free/css/all.css";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
// Vuetify's CSS styles
import "vuetify/dist/vuetify.min.css";

Vue.config.productionTip = false;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};

Vue.use(VueHtmlToPaper, options);
Vue.use(Notifications);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi"
});

// Vue.use(VueNotification, {
//   timer: 20,
//   position: "topCenter",
//   title: "Vue notification",
//   error: {
//     background: "#F56565",
//     color: "white"
//   },
//   success: {
//     background: "#38A169",
//     color: "white"
//   },
//   warning: {
//     background: "#ED8936",
//     color: "white"
//   }
// });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
