import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class TeamWorkerGroupService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  get({ user_id, query }) {
    return http.get(`user_workergroups/${user_id}`, {
      params: query
    });
  }

  getPaginated({ user_id, query }) {
    return http.get(`user_workergroups/${user_id}?paginate=true`, {
      params: query
    });
  }

  create({ data, query }) {
    return http.post(`user_workergroup`, data, {
      params: query
    });
  }

  update({ id, data, query }) {
    return http.put(`user_workergroup/${id}`, data, {
      params: query
    });
  }

  remove({ user_id, workergroup_id }) {
    return http.delete(`user_workergroup/${user_id}/${workergroup_id}`);
  }
}

export default new TeamWorkerGroupService();
