<template>
  <div class="white mainHero">
    <v-container>
      <v-row class="">
        <v-col cols="6">
          <div class="logo">
            <a href="/"
              ><v-img src="/assets/images/ABClogo.png" width="160" height="152"
            /></a>
          </div>

          <div class="hero-copy">
            <p class="display-2 font-weight-bold primary--text mb-6">
              Welcome ABC Recycling
            </p>
            <p class="text-justify">
           
            </p>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="hero-image">
            <div class="text-xs-right text-right rounded-m">
              <v-btn
                @click="loginDialog = true"
                color="secondary"
                class="login-button"
              >
                <v-icon left>mdi-fingerprint</v-icon>Login
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-16">
        <v-col cols="6"> </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="loginDialog" persistent max-width="1100">
      <v-card height="700" class="loginPopup">
        <v-toolbar flat class="loginToolbar">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon color="secondary" @click="loginDialog = false"
              >mdi-close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5" class="loginLeft">
                <v-img
                  class="loginImage"
                  src="/assets/images/loginMain.png"
                  width="934"
                />
              </v-col>
              <v-col cols="7" class="loginRight">
                <v-container class="loginForm">
                  <v-row justify="center" align="center"
                    ><v-col cols="9" align="center"
                      ><v-img
                        src="/assets/images/ABClogo.png"
                        width="120"/></v-col
                  ></v-row>
                  <v-row justify="center" align="center"
                    ><v-col cols="9" align="center" class="pb-8 pt-6"
                      ><h1>Sign in to your account</h1></v-col
                    ></v-row
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="8">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          id="login_email"
                          v-model="user.email"
                          :rules="emailRules"
                          label="E-mail"
                          background-color="white"
                          required
                          filled
                        ></v-text-field>

                        <v-text-field
                          id="login_password"
                          v-model="user.password"
                          :rules="[v => !!v || 'Password is required']"
                          label="Password"
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPass ? 'text' : 'password'"
                          @click:append="showPass = !showPass"
                          background-color="white"
                          required
                          counter
                          filled
                        ></v-text-field>

                        <div>
                          <router-link to="recovery">
                            <p class="fg-pw text-right">
                              Forgot your password?
                            </p>
                          </router-link>
                        </div>
                        <div>
                          <v-btn
                            @submit="handleLogin"
                            width="100%"
                            @click="handleLogin"
                            class="btn-login mt-6"
                            color="secondary"
                            >Login</v-btn
                          >
                        </div>

                        <v-alert v-if="message" type="error" class="mt-5">{{
                          message.message
                        }}</v-alert>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Role } from "@/helpers/roles";
import User from "../models/user";
//import NotificationBell from "vue-notification-bell";
import AnnouncementsService from "../services/announcements.service";

export default {
  name: "Header",
  props: {
    title: String,
    intro: String,
    org: String,
    size: String
  },
  data() {
    return {
      user: new User("", ""),
      valid: true,
      message: "",
      password: "",
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      showPass: false,

      apiError: "",
      loginDialog: false,
      menu: false,
      drawer: null,
      color: "primary",
      timer: 5,
      infiniteTimer: false,
      showLeftIcn: true,
      showCloseIcn: true,
      count: 0,
      buttonClicked: false,
      showNotifBell: false,
      notifications: [],
      tab: null,
      tabs: {
        "asbestos.whs.academy": "asbestos",
        "lead.whs.academy": "lead",
        "netflix.whs.academy": "netflix",
        "localhost:8080": "netflix"
      }
    };
  },
  async created() {
    if (this.currentUser) {
      if (this.isUserBasic) {
        this.$router.push("/mycourses");
      }

      if (!this.isAdminEditor) {
        await this.setNotifications();
        this.notifications.forEach(item => {
          this.show(
            "custom-template",
            item.title,
            item.message,
            item.body,
            item.id
          );
        });
        this.showNotifBell = true;
      }
      if (
        !this.isAdminEditor &&
        !this.isUserReviewer &&
        !this.currentUser.user.onboard
      ) {
        this.$router.push("/on-boarding");
      }
    }
    this.tab = this.tabs[window.location.host];
  },
  computed: {
    notifCount() {
      return this.notifications.length;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
    isOrgOwner() {
      let orgOwner = false;

      this.currentUser.user.roles.forEach(item => {
        if (
          item.role_id === Role.UserFull &&
          this.currentUser.user.id ===
            this.currentUser.user.organizations.user_id
        ) {
          orgOwner = true;
        }
      });
      return orgOwner;
    },
    isUserFull() {
      let userFull = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },
    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    },
    getConfig: function() {
      return {
        position: this.position,
        timer: this.timer,
        infiniteTimer: this.infiniteTimer,
        showLeftIcn: true,
        showCloseIcn: true
        //title: this.title
      };
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("quizStatus/clearQuiz");
      this.$router.push("/");
    },

    async setNotifications() {
      const response = await AnnouncementsService.get({});
      this.notifications = response.data.data.slice();
      if (this.notifications.length > 0)
        this.notifications = this.notifications.filter(
          item => item.user_announcements.dismissed === 0
        );
    },

    displayNotification(message) {
      this.$notify(message);
    },

    async triggerClose(id) {
      //remove from the list
      const index = this.notifications.findIndex(item => item.id === id);
      this.notifications.splice(index, 1);

      if (this.$route.name === "notifications") {
        //update list in parent
        this.$emit("dismissClicked", id);
      }

      //set the dissmissed to 1 in the DB
      await AnnouncementsService.remove({
        id
      });
      this.$notify.close(id);
    },

    show(type, title, message, body, id) {
      this.$notify({
        id,
        group: "notifications",
        title: title,
        text: message,
        type,
        data: {
          body: body,
          id: id
        },
        close: function() {
          console.log("id", this.id);
        }
      });
    },
    goToUrl(url) {
      location.href = url;
    },
    handleLogin() {
      this.loading = true;

      const isValid = this.$refs.form.validate();

      if (isValid) {
        if (this.user.email && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            response => {
              this.$store.commit(
                "updateKeepAliveTimerDefault",
                response.session_seconds_left
              );
              if (!this.isAdminEditor && !this.isUserReviewer)
                this.$router.push("/mycourses");
              else if (this.isAdminEditor || this.isUserReviewer)
                this.$router.push("/main");
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      }
    }
  },
  components: {
    //NotificationBell
  }
};
</script>
<style lang="scss" scoped>
.wrap-text {
  word-wrap: break-word;
}
.notification.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  color: #495061;
  background: #eaf4fe;
  border: 1px solid #d4e8fd;
  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
    color: #2589f3;
  }
}
.custom-template {
  // display: flex;
  // flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  &,
  & > div {
    box-sizing: border-box;
  }

  background: #e8f9f0;
  color: #333333;
  border: 2px solid #d0f2e1;
  .custom-template-icon {
    flex: 0 1 auto;
    color: #15c371;
    font-size: 32px;
    padding: 0 10px;
  }
  .custom-template-close {
    position: absolute;
    top: 0px;
    right: -15px;
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

.notifications {
  display: block;
  position: fixed;
  z-index: 5000;
}
.notification-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}
.notification {
  display: block;
  box-sizing: border-box;
  background: #fff;
  text-align: left;
}
.notification-title {
  font-weight: 600;
}
.vue-notification {
  font-size: 12px;
  padding: 10px;
  margin: 0 5px 5px;
  color: #fff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}
.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}
.vue-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}
.vue-notification.success {
  background: #68cd86;
  border-left-color: #42a85f;
}
.vn-fade-enter-active,
.vn-fade-leave-active,
.vn-fade-move {
  transition: all 0.5s;
}
.vn-fade-enter,
.vn-fade-leave-to {
  opacity: 0;
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}
.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}
</style>
