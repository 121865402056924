<template>
  <v-row>
    <v-col cols="12">
      <template v-for="course in courses">
        <v-card class="pa-0 mx-auto mb-16" tile elevation="8" :key="course.id">
          <v-row no-gutters>
            <template v-if="course.image">
              <v-col cols="12" md="4">
                <v-img
                  src="course.image"
                  class="course__image"
                  aspect-ratio="1.7"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="grey lighten-2 fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="secondary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </template>

            <v-col class="pa-8">
              <h3 class="text-h3 mb-5">{{ course.title }}</h3>
              <p class="text-body-2">
                <span class="pr-5">
                  <v-icon color="gray">mdi-clock-outline</v-icon>
                  {{ timeConvert(course.duration) }}
                </span>
                <span class="pr-5">
                  <v-icon color="gray">mdi-tag-outline</v-icon>
                  {{ course.category }}
                </span>
              </p>

              <div v-html="course.summary"></div>
              <v-btn
                :to="`/plans/${course.id}`"
                rounded
                color="secondary"
                class="mt-5"
                >Enter course</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Courses",
  props: {
    courses: Array
  },
  methods: {
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rminutes > 1 && rhours > 1) {
        return rhours + " hours and " + rminutes + " minutes";
      } else if (rminutes > 1 && rhours === 0) {
        return rminutes + " minutes";
      } else {
        return rhours + " hours";
      }
    }
  }
};
</script>
