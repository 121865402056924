<template>
  <div class="home grey lighten-3">
    <HeaderHome></HeaderHome>

    <div class="grey lighten-3 py-15">
      <v-container>
        <v-row>
          <v-col>
            <course-list />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <start-brand />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderHome from "@/components/HeaderHome";
import CourseList from "@/components/CourseList";
import StartBrand from "@/components/StartBrand";
//import DemoCTA from "@/components/DemoCTA";
import { Role } from "@/helpers/roles";

export default {
  data() {
    return {
      tab: null,
      tabs: {
        "asbestos.whs.academy": "asbestos",
        "lead.whs.academy": "lead",
        "netflix.whs.academy": "netflix",
        "localhost:8080": "netflix"
      }
    };
  },

  components: {
    HeaderHome,
    CourseList,
    StartBrand
    //DemoCTA
  },

  computed: {
    isUserFull() {
      let userFull = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (this.currentUser) {
      if (this.isAdminEditor || this.isUserReviewer) {
        this.$router.push("/main");
      }
      if (this.isUserFull) {
        this.$router.push("/mycourses");
      }
    }
    this.tab = this.tabs[window.location.host];
  }
};
</script>
