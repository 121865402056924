import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class OnboardService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  getOnboard() {
    return http.get(`onboard`);
  }

  getUserOnboard() {
    return http.get(`user_onboard`);
  }

  postUserOnboard({ id }) {
    return http.post(`user_onboard/${id}`);
  }

  postUserOnboardFinalize() {
    return http.post(`user_onboard_finalize`);
  }
}

export default new OnboardService();
