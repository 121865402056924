<template>
  <div class="dashboard">
    <v-container v-if="!isLoading" class="my-2">
      <v-row v-if="hasLicensesAvailable">
        <v-col cols="12">
          <v-data-table
            :loading="dataLicensesLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="acc_headers"
            :items="acc_licenses"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-1 align-center"
            :hide-default-footer="true"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <h3>My Licenses</h3>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <p>{{ item.status }}</p>
            </template>

            <template v-slot:item.user.first_name="{ item }">
              <p>{{ updateDashboardUser(item, "first_name") }}</p>
            </template>

            <template v-slot:item.user.last_name="{ item }">
              <p>{{ updateDashboardUser(item, "last_name") }}</p>
            </template>

            <template v-slot:item.user.email="{ item }">
              <p>{{ updateDashboardUser(item, "email") }}</p>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import LicenseService from "@/services/license.service";

// import MyCourses from "@/components/MyCourses";

export default {
  name: "MyLicensesSummary",
  data() {
    return {
      loadingForm: false,
      alert: "",
      dataLicensesLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      search: "",
      acc_user: {
        first_name: "",
        last_name: "",
        email: ""
      },
      acc_licenses: [],
      headers: [
        { text: "ID", value: "id", width: "6%" },
        { text: "Plan", value: "courseDetails[0].title" },
        { text: "Date created", value: "created_at" },
        { text: "Status", value: "status" }
      ],
      acc_headers: [
        { text: "First Name", value: "user.first_name", width: "20%" },
        { text: "Last Name", value: "user.last_name", width: "20%" },
        { text: "Email", value: "user.email" },
        { text: "Status", value: "status" }
      ],
      expanded: [],
      singleExpand: true,
      isLoading: false
    };
  },

  computed: {
    hasLicensesAvailable() {
      if (this.acc_licenses.length) {
        if (this.acc_licenses.find(e => e.status == "inactive")) {
          return this.acc_licenses.filter(e => e.status == "inactive").length;
        } else {
          return "full";
        }
      } else {
        return false;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    updateDashboardUser(item, key) {
      if (item && item.user && item.user[key]) {
        return item.user[key];
      } else if (item && item.status == "pending" && item["granted_" + key]) {
        return item["granted_" + key];
      }
    },

    async initialize() {
      this.getMyLicenses();
    },

    async getMyLicenses() {
      this.dataLicensesLoading = true;
      const myLicenses = await LicenseService.getMyLicenses({});
      this.acc_licenses = myLicenses.data.data;
      this.dataLicensesLoading = false;
    }
  }
};
</script>
