<template>
  <div class="managegroups">
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="org_workergroups"
            :disable-sort="true"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
            class="elevation-5 align-center"
            :hide-default-footer="hidePagination"
            :disable-pagination="hidePagination"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <h3>My Workergroups</h3>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import LocationService from "@/services/location.service";
import WorkgroupService from "@/services/workergroup.service";
import WGtypeService from "@/services/wgtype.service";

export default {
  data: () => ({
    dataLoading: true,
    options: {},
    itemsTotal: 0,
    currentPage: 1,
    lastPage: null,
    alert: "",
    headers: [
      { text: "Location", align: "start", value: "locationName" },
      // { text: "Worker Group Type", align: "start", value: "wgtypeName" },
      { text: "Worker Group Name", align: "start", value: "name" }
    ],

    org_locations: [],
    org_workergroups: [],
    hidePagination: true,
    org_wgtypes: []
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedWorkergroups();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    async initialize() {
      const responseLocation = await LocationService.find({});
      const responseWGtype = await WGtypeService.find({});
      this.org_wgtypes = responseWGtype.data.data.slice();
      this.org_locations = responseLocation.data.data.slice();
      this.getPagedWorkergroups();
    },

    async getPagedWorkergroups() {
      this.dataLoading = true;
      let workergroups = [];

      const { page, itemsPerPage } = this.options;

      const responseWorkgroup = await WorkgroupService.find({
        query: {
          page: page
        }
      });

      workergroups = responseWorkgroup.data.data.slice();
      this.itemsTotal = responseWorkgroup.data.meta.total;
      this.lastPage = responseWorkgroup.data.meta.last_page;

      if (itemsPerPage > 0) {
        workergroups = workergroups.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.org_workergroups = workergroups;

      if (this.org_workergroups.length > 10) this.hidePagination = false;

      this.dataLoading = false;
      this.hydrateLocation();
      //this.hydrateWGtype();
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    hydrateLocation() {
      this.org_workergroups.forEach(wg => {
        const result = this.org_locations.filter(obj => {
          return obj.id === wg.location_id;
        });

        wg["locationName"] = result[0].name;
      });
    },

    // hydrateWGtype() {
    //   this.org_workergroups.forEach(wg => {
    //     const result = this.org_wgtypes.filter(obj => {
    //       return obj.id === wg.wgtype_id;
    //     });
    //     wg["wgtypeName"] = result[0].name;
    //   });
    // }
  }
};
</script>
