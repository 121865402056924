<template>
  <v-container class="primary" fluid>
    <v-container class="py-12">
      <v-row class="primary">
        <v-col cols="8" class="start-brand">
          <p class="turqoise--text copy-1">
            Ready to get started?
          </p>
          <p class="white--text copy-2">
            Login to take your available courses.
          </p>
        </v-col>

        <v-col cols="4" class="text-right">
          <div
            class="rounded-m"
            v-if="!currentUser && this.$route.path !== '/login'"
          >
            <v-btn to="/login" color="turqoise" class="bottom-login-btn py-6 px-12">
              Take your courses
            </v-btn>
          </div></v-col
        >
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "Response",
  props: {
    item: {}
  },
  components: {},
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    onClickButton(itemId) {
      this.$emit("clicked", itemId);
    }
  }
};
</script>
<style scoped>
.mybutton {
  text-transform: none !important;
}
</style>
