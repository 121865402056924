<template>
  <v-card class="mx-auto">
    <v-system-bar color="secondary" dark class="pa-6">
      <h3 class="text-overline">Account Setup</h3>
    </v-system-bar>

    <div class="chapter-navigation text-body-2">
      <v-list disabled>
        <v-list-item-group v-model="currentOnboardIndex">
          <v-list-item v-for="(item, index) in onboard" :key="index">
            <v-list-item-content>
              {{ index + 1 }}. {{ item.label }}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "ChapterNavigation",
  props: [
    "onboard",
    "currentOnboard",
    "currentOnboardId",
    "currentOnboardIndex"
  ],
  computed: {
    selectedItem() {
      return this.currentOnboardId - 1;
    }
  }
};
</script>
