import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#2F91AE",
        secondary: "#96B93D",
        accent: "#607d8b",
        error: "#EB5757",
        warning: "#F2C94C",
        info: "#2196f3",
        success: "#27AE60",
        turqoise: "#D4ECEC"
      }
    }
  }
});

export default vuetify;
