<template>
  <div class="courses">
    <Header
      size="medium"
      title="COVID-19 Online Risk Assessment (CORA) Planning Tool"
      name="course-header"
    ></Header>

    <v-dialog v-model="conditionsToDoCourses" persistent max-width="310">
      <v-card>
        <v-card-title class="headline">
          Your account is not <br />
          configured correctly
        </v-card-title>
        <v-card-text v-if="isUserFull"
          >You need update your <a href="/account">Account setting</a><br />
          Please check you have a location & workergroup selected</v-card-text
        >
        <v-card-text v-else
          >Contact your administrator to configurate correctly a location &
          workergorup</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <router-link to="/account">
            <v-btn color="red darken-1" text>
              OK
            </v-btn>
          </router-link>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="!conditionsToDoCourses" class="white py-15">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="text-h2 mb-5">Plans</h2>
          </v-col>
        </v-row>

        <!-- Featured Course Component -->

        <v-alert v-if="apiError" type="error">{{ apiError }}</v-alert>

        <template v-if="isLoaded">
          <template v-if="courses.length > 0" transition="fade-transition">
            <Courses :courses="courses" />
          </template>
          <template v-else>
            <v-alert border="top" colored-border type="warning" elevation="5"
              >There are no plans available at this time. Please check back
              soon.</v-alert
            >
          </template>
        </template>
        <template v-else>
          <!-- loading -->
          <v-col cols="12">
            <p class="text-center text-overline mb-8">Loading Courses</p>
            <v-progress-linear
              color="secondary"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </template>
      </v-container>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Courses from "@/components/Courses";
import OrgCoursesService from "../services/orgcourses.service";
import { Role } from "@/helpers/roles";

export default {
  components: {
    Header,
    Courses
  },
  data: () => ({
    courses: [],
    apiError: "",
    isLoaded: false
  }),

  computed: {
    isUserFull() {
      let userFull = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },

    conditionsToDoCourses() {
      if(this.isAdminEditor || this.isUserReviewer)
        return false;
      if (
        this.currentUser.user.workergroups[0] &&
        this.currentUser.user.workergroups[0].id &&
        this.currentUser.user.locations &&
        this.currentUser.user.locations.id
      ) {
        return false;
      } else {
        return true;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    },

    orgId() {
      return this.$store.state.auth.user.user.organizations.id;
    },

    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    }
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      this.initialize();
    }
  },

  methods: {
    async initialize() {
      try {
        // const response = await OrgCoursesService.get({
        //   orgId: this.orgId
        // });
        const response = await OrgCoursesService.get({});
        //console.log(response);
        this.courses = response.data.slice();
        this.courses = this.courses.filter(c => c.custom === 0);
        this.isLoaded = true;
      } catch (e) {
        console.log("error getting courses", e);
      }
    }
  }
};
</script>
