import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Courses from "../views/Courses.vue";
import OnBoarding from "../views/OnBoarding.vue";
import Login from "../views/Login.vue";
import MainPage from "../views/MainPage.vue";
import store from "../store";
import { Role } from "@/helpers/roles";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        title: "Online Risk Assessment Planning Tool",
        metaTags: [
          {
            name: "description",
            content: "Welcome. Your Online Risk Assessment Planning Tool."
          },
          {
            property: "og:description",
            content: "Welcome. Your Online Risk Assessment Planning Tool."
          }
        ]
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Login",
        metaTags: [
          {
            name: "description",
            content:
              "Login to create your personalised online risk assessment report."
          },
          {
            property: "og:description",
            content:
              "Login to create your personalised online risk assessment report."
          }
        ]
      }
    },
    {
      path: "/signup/:token?/",
      name: "signup",
      component: () =>
        import(/* webpackChunkName: "courses" */ "../views/Signup.vue"),
      meta: {
        title: "Sign up",
        metaTags: [
          {
            name: "description",
            content:
              "Sign up to create your personalised online risk assessment report."
          },
          {
            property: "og:description",
            content:
              "Sign up to create your personalised online risk assessment report."
          }
        ]
      }
    },
    {
      path: "/recovery/:token?/:user?",
      name: "recovery",
      component: () =>
        import(/* webpackChunkName: "recovery" */ "../views/Recovery.vue"),
      meta: {
        title: "Recovery",
        metaTags: [
          {
            name: "description",
            content: "Recovery your password from ABC."
          },
          {
            property: "og:description",
            content: "Recovery your password from ABC."
          }
        ]
      }
    },
    {
      path: "/payment",
      name: "payment",
      component: () =>
        import(/* webpackChunkName: "courses" */ "../views/Payment.vue"),
      meta: {
        title: "Payment - ABC",
        metaTags: [
          {
            name: "description",
            content:
              "Sign up to ABC to create your personalised online risk assessment report."
          },
          {
            property: "og:description",
            content:
              "Sign up to ABC to create your personalised online risk assessment report."
          }
        ]
      }
    },
    {
      path: "/thankyou",
      name: "thankyou",
      component: () =>
        import(/* webpackChunkName: "courses" */ "../views/Thankyou.vue"),
      meta: {
        title: "Thank you for signing up - ABC",
        metaTags: [
          {
            name: "description",
            content:
              "Thank you for signing up to ABC to create your personalised online risk assessment report."
          },
          {
            property: "og:description",
            content:
              "Thank you for signing up to ABC to create your personalised online risk assessment report."
          }
        ]
      }
    },
    {
      path: "/plans",
      name: "plans",
      component: Courses,
      meta: {
        requiresAuth: [],
        title: "Courses - ABC",
        metaTags: [
          {
            name: "description",
            content: "Courses"
          },
          {
            property: "og:description",
            content: "Courses"
          }
        ]
      }
    },
    {
      path: "/main",
      name: "mainpage",
      component: MainPage,
      meta: {
        requiresAuth: [],
        title: "Courses - ABC",
        metaTags: [
          {
            name: "description",
            content: "Courses"
          },
          {
            property: "og:description",
            content: "Courses"
          }
        ]
      }
    },
    {
      path: "/on-boarding",
      name: "onBoarding",
      component: OnBoarding,
      meta: {
        requiresAuth: [],
        title: "On Boarding",
        metaTags: [
          {
            name: "description",
            content: "On-Boarding"
          },
          {
            property: "og:description",
            content: "On-Boarding"
          }
        ]
      }
    },
    {
      path: "/userguidevideo",
      name: "userguidevideo",
      component: () => import("../views/UserGuideVideo.vue"),
      meta: {
        requiresAuth: [],
        title: "User Guide Video",
        metaTags: [
          {
            name: "description",
            content: "User Guide Video"
          },
          {
            property: "og:description",
            content: "User Guide Video"
          }
        ]
      }
    },
    {
      path: "/plans/:id",
      name: "plan",
      component: () => import("../views/CourseSingle.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Course"
      }
    },
    {
      path: "/plans/:id?chapter=:chapterId",
      name: "chapter",
      component: () => import("../views/CourseSingle.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Course"
      }
    },
    {
      path: "/quizsingle/:id",
      name: "quizsingle",
      component: () => import("../views/QuizSingle.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Quiz"
      }
    },
    {
      path: "/reviewerdashboardorg/:id",
      name: "reviewerdashboardorg",
      component: () => import("../views/ReviewerDashboardOrg.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Reports Review Dashboard"
      }
    },
    {
      path: "/orgquizreport/:id",
      name: "orgquizreport",
      component: () => import("../views/OrgQuizReport.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Organization Report Review"
      }
    },
    {
      path: "/plans/:id?chapter=:chapterId&page=:pageId",
      name: "page",
      component: () => import("../views/CourseSingle.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "Course"
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("../views/DashboardWG.vue"),
      meta: {
        requiresAuth: [Role.WGUser],
        title: "Dashboard",
        metaTags: [
          {
            name: "description",
            content:
              "View your personalised COVID-19 Online Risk Assessment reports"
          },
          {
            property: "og:description",
            content:
              "View your personalised COVID-19 Online Risk Assessment reports"
          }
        ]
      }
    },
    {
      path: "/org-dashboard",
      name: "orgdashboard",
      component: () => import("../views/DashboardOrg.vue"),
      meta: {
        requiresAuth: [Role.OrgAdmin],
        title: "Admin Dashboard",
        metaTags: [
          {
            name: "description",
            content:
              "View and manage your companies personalised COVID-19 Online Risk Assessment reports."
          },
          {
            property: "og:description",
            content:
              "View and manage your companies personalised COVID-19 Online Risk Assessment reports."
          }
        ]
      }
    },
    {
      path: "/manage-locations",
      name: "managelocations",
      component: () => import("../views/ManageLocations.vue"),
      meta: {
        requiresAuth: [Role.OrgAdmin, Role.OrgLocAdmin, Role.WGUser],
        title: "Manage Locations",
        metaTags: [
          {
            name: "description",
            content: "Manage your organizations locations"
          },
          {
            property: "og:description",
            content: "Manage your organizations locations"
          }
        ]
      }
    },
    {
      path: "/manage-workergroup-types",
      name: "managewgtype",
      component: () => import("../views/ManageWGTypes.vue"),
      meta: {
        requiresAuth: [Role.OrgAdmin],
        title: "Manage Worker Group Types",
        metaTags: [
          {
            name: "description",
            content: "Manage your organizations worker group types."
          },
          {
            property: "og:description",
            content: "Manage your organizations worker group types."
          }
        ]
      }
    },
    {
      path: "/manage-workergroups",
      name: "managegroups",
      component: () => import("../views/ManageGroups.vue"),
      meta: {
        requiresAuth: [Role.OrgAdmin],
        title: "Manage Worker Groups",
        metaTags: [
          {
            name: "description",
            content: "Manage your organizations worker groups."
          },
          {
            property: "og:description",
            content: "Manage your organizations worker groups."
          }
        ]
      }
    },
    {
      path: "/manage-users",
      name: "manageusers",
      component: () => import("../views/ManageUsers.vue"),
      meta: {
        requiresAuth: [Role.OrgAdmin],
        title: "Manage Users",
        metaTags: [
          {
            name: "description",
            content: "Manage your organizations users."
          },
          {
            property: "og:description",
            content: "Manage your organizations users."
          }
        ]
      }
    },
    {
      path: "/report/:id",
      name: "report",
      component: () => import("../views/ReportSingle.vue"),
      props: true,
      meta: {
        requiresAuth: [],
        title: "My Report"
      }
    },
    {
      path: "/account",
      name: "account",
      component: () => import("../views/Account.vue"),
      meta: {
        requiresAuth: [],
        title: "My Account",
        metaTags: [
          {
            name: "description",
            content: "My Account"
          },
          {
            property: "og:description",
            content: "My Account"
          }
        ]
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../views/Contact.vue"),
      meta: {
        title: "Get in touch",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/mycourses",
      name: "mycourses",
      component: () => import("../views/MyCourses.vue"),
      meta: {
        title: "My courses",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/myquizzes",
      name: "myquizzes",
      component: () => import("../views/MyQuizzes.vue"),
      meta: {
        title: "All Quizzes",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/riskprofiles",
      name: "riskprofiles",
      component: () => import("../views/RiskProfiles.vue"),
      meta: {
        title: "My courses",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/coursechapters/:id",
      name: "coursechapters",
      component: () => import("../views/CourseChapters.vue"),
      meta: {
        title: "Chapters",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/chapterquizzes/:id",
      name: "chapterquizzes",
      component: () => import("../views/ChapterQuizzes.vue"),
      meta: {
        title: "Quizzes",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/quizquestions/:id",
      name: "quizquestions",
      component: () => import("../views/QuizQuestions.vue"),
      meta: {
        title: "Quiz",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/managequestions",
      name: "managequestions",
      component: () => import("../views/ManageQuestionBank.vue"),
      meta: {
        title: "Manage Question Bank",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/quizreport/:id",
      name: "quizreport",
      component: () => import("../views/QuizReport.vue"),
      meta: {
        title: "Quiz Report",
        metaTags: [
          {
            name: "description",
            content: ""
          },
          {
            property: "og:description",
            content: ""
          }
        ]
      }
    },
    {
      path: "/quizcertificate/:id",
      name: "quizcertificate",
      component: () => import("../views/QuizCertificate.vue"),
      meta: {
        title: "Quiz Certificate",
        metaTags: [
          {
            name: "description",
            content: ""
          },
          {
            property: "og:description",
            content: ""
          }
        ]
      }
    },
    {
      path: "/managecategories",
      name: "managecategories",
      component: () => import("../views/ManageCategories.vue"),
      meta: {
        title: "Manage Master Question Bank",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/announcements",
      name: "announcements",
      component: () => import("../views/ManageAnnouncements.vue"),
      meta: {
        title: "Manage Announcements",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("../views/ManageNotifications.vue"),
      meta: {
        title: "Manage Notifications",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/reviewers",
      name: "reviewers",
      component: () => import("../views/ManageReviewers.vue"),
      meta: {
        title: "Manage Master Question Bank",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "/orgreports",
      name: "orgreports",
      component: () => import("../views/OrgReports.vue"),
      meta: {
        title: "Manage Master Question Bank",
        metaTags: [
          {
            name: "description",
            content: "Ready to get started? Contact us for a free demo."
          },
          {
            property: "og:description",
            content: "Ready to get started? Contact us for a free demo."
          }
        ]
      }
    },
    {
      path: "*",
      component: () => import("../views/404.vue"),
      meta: {
        title: "404 Not Found",
        metaTags: [
          {
            name: "description",
            content: "404 Not Found"
          },
          {
            property: "og:description",
            content: "404 Not Found"
          }
        ]
      }
    }
  ],

  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  }
});

export default router;

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  // redirect to login page if not logged in and trying to access a restricted page
  const { requiresAuth } = to.meta;
  //stored user state
  const loggedIn = store.state?.auth?.user?.user;

  if (requiresAuth) {
    if (!loggedIn) {
      // not logged in so redirect to login page with the return url
      return next({ path: "/login" });
    }
    // check if route is restricted by role
    if (requiresAuth.length > 0) {
      let hasAccess = false;
      //loop through the stored user roles - if page meta matches a stored user role set allow access to true
      loggedIn.roles.forEach(item => {
        if (requiresAuth.includes(item.role_id)) {
          hasAccess = true;
        }
      });

      // If allow access is true, proceed to link
      if (hasAccess) {
        next();
      } else {
        // role not authorised so redirect to courses
        return next({ path: "/plans" });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});
