<template>
  <v-footer light padless class="grey lighten-5 flex align-center myfooter">
    <v-container>

      <v-row class="flex align-center">
        <v-col cols="4" sm="2">
          <router-link to="/">
            <v-img
              alt="WHSA Logo"
              max-height="50"
              contain
              src="/assets/images/WHSAcademy.svg"
              height="100"
            />
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
