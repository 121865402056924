<template>

  <v-app>
    <v-main style="padding-left: 0px !important;">
      <router-view />
    </v-main>
    <v-dialog v-model="this.startInternalCountdown" persistent max-width="290">
      <v-card>
        <v-card-title v-show="loggedOut" class="headline">
          Logged out due inactivity.
        </v-card-title>
        <v-card-text v-show="!loggedOut" class="pt-4">
          You will automatically be logged out due to inactivity in
          {{
            new Date(this.internalCountdown * 1000).toISOString().substr(11, 8)
          }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="loggedOut"
            color="green darken-1"
            text
            @click="keepAliveLogout()"
          >
            Ok
          </v-btn>
          <v-btn
            v-show="!loggedOut"
            color="green darken-1"
            text
            @click="stayAlive()"
          >
            Resume Session
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Footer />
  </v-app>

</template>

<style lang="scss">
@import "./assets/scss/global-styles.scss";
</style>

<script>
import Footer from "./components/Footer";
import authServices from "@/services/auth.service";

export default {
  name: "App",

  data() {
    return {
      keepAliveIntervalClock: null,
      countdownInternalClock: null,
      internalCountdown: null,
      startInternalCountdown: false,
      loggedOut: false
    };
  },

  components: {
    Footer
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  updated() {
    clearInterval(this.keepAliveIntervalClock);
    if (this.loggedIn) {
      if (!this.startInternalCountdown) {
        //Keep Alive on every route change
        this.$store.commit(
          "updateKeepAliveTimer",
          this.$store.state.keepAliveTimerDefault
        );
        this.internalCountdown = this.$store.state.keepAliveTimerDefault / 2;
        authServices.keepAlive();
        this.keepAliveIntervalClock = setInterval(() => {
          if (
            this.$store.state.keepAliveTimer <=
            this.$store.state.keepAliveTimerDefault / 2
          ) {
            this.startInternalCountdown = true;
            this.countDownTimer();
          } else {
            this.$store.commit(
              "updateKeepAliveTimer",
              this.$store.state.keepAliveTimer - 10
            );
          }
        }, 10000);
      }
    }
  },

  methods: {
    stayAlive() {
      this.startInternalCountdown = false;
      clearTimeout(this.countdownInternalClock);
    },

    countDownTimer() {
      if (this.internalCountdown == 0) {
        this.internalCountdown = null;
        this.loggedOut = true;
        this.$store.commit("updateKeepAlive", true);
        this.$store.dispatch("auth/logout");
        this.$store.dispatch("quizStatus/clearQuiz");
      } else {
        this.countdownInternalClock = setTimeout(() => {
          this.internalCountdown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },

    keepAliveLogout() {
      // this.$store.commit('updateKeepAliveTimer', 600);
      this.$store.commit("updateKeepAlive", false);
      this.startInternalCountdown = false;
      this.loggedOut = false;
      this.$router.push("/login");
    }
  }
};
</script>
