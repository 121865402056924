<template>
  <div class="contact">
    <Header></Header>

    <v-container class="my-3">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">{{ title }}</h1>
          <v-divider class="mt-8 mb-5"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div v-html="bodyCopy"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/Header";
import { Role } from "@/helpers/roles";

export default {
  components: {
    Header
  },
  data() {
    return {
      title: "",
      bodyCopy: ""
    };
  },
  computed: {
    isUserFull() {
      let userFull = false;

      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  created() {
    if (this.isUserReviewer) {
      this.title = "Reviewer Dashboard";
      this.bodyCopy =
        "<p>Your reviewer account enables you to review and provide feedback on reports generated by organizations. In order to access the reports belonging to a specific orgnaization, please contact the organization administrator providing them with your person UID code. Once you are granted access, you may review, approve, reject/approve their reports.</p>";
    } else if (this.isAdminEditor) {
      this.title = "Welcome WHS Editor Admin";
      this.bodyCopy =
        "<p>This administrative dashboard enables WHS staff to add and manage master content on the system.</p>";
    }
    this.initialize();
  }
};
</script>
