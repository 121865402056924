import _ from "lodash";

const quizzes = JSON.parse(localStorage.getItem("quizzes"));
const initialState = quizzes
  ? quizzes
  : { completed: {}, myQuizzes: [], reportId: null };

export const quizStatus = {
  namespaced: true,
  state: initialState,
  actions: {
    complete({ commit }, quiz) {
      commit("quizComplete", quiz);
    },
    createReport({ commit }, report) {
      commit("reportCreated", report);
    },
    clearQuiz({ commit }) {
      commit("clearQuiz");
    }
  },
  mutations: {
    reportCreated(state, report) {
      state.reportId = report;
      state.myQuizzes = [];
    },
    quizComplete(state, quiz) {
      // if (!state.completed[quiz.reportId]) {
      //   state.completed[quiz.reportId] = {};
      // }
      // state.completed[quiz.reportId][quiz.id] = quiz.results;
      state.completed[quiz.id] = quiz.results;

      // check if quiz results already in array
      // add it if not otherwise replace it
      if (!_.find(state.myQuizzes, { quizId: quiz.id })) {
        state.myQuizzes.push({
          quizId: quiz.id,
          quizName: quiz.quizName,
          quizRisk: quiz.riskResult,
          quizRiskTotal: quiz.riskScore,
          responses: quiz.results
        });
      } else {
        const index = _.findIndex(state.myQuizzes, { quizId: quiz.id });
        state.myQuizzes.splice(index, 1, {
          quizId: quiz.id,
          quizName: quiz.quizName,
          quizRisk: quiz.riskResult,
          quizRiskTotal: quiz.riskScore,
          responses: quiz.results
        });
      }
    },
    clearQuiz(state) {
      state.reportId = null;
      state.completed = {};
      state.myQuizzes = [];
    }
  }
};
