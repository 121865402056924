<template>
  <div>
    <Header> </Header>
    <v-container class="my-3">
      <v-row>
        <v-col cols="12" sm="4" md="3" class="order-last order-sm-first">
          <AccountSetupNavigation
            :onboard="onboard"
            :currentOnboard="currentOnboard"
            :currentOnboardId="currentOnboardId"
            :currentOnboardIndex="currentOnboardIndex"
            :user_license_id="user_license_id"
            :onboardLength="onboardLength"
          />
        </v-col>
        <v-col cols="12" sm="8" md="9" class="course-content">
          <AccountSetupContent
            :currentOnboardId="currentOnboardId"
            :user_license_id="user_license_id"
            :currentOnboard="currentOnboard"
            :currentOnboardIndex="currentOnboardIndex"
            :onboardLength="onboardLength"
            :onboard="onboard"
            :currentUser="currentUser"
            :nextBtnDisabled="nextBtnDisabled"
            @nextStep="nextStep"
            @backStep="backStep"
            @setNextBtnDisabled="setNextBtnDisabled"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from "@/components/Header";
import AccountSetupContent from "@/components/AccountSetupContent";
import AccountSetupNavigation from "@/components/AccountSetupNavigation";
import OnboardService from "@/services/onboard.service";
import LicenseService from "@/services/license.service";
import LocationService from "@/services/location.service";
import WorkergroupService from "@/services/workergroup.service";

export default {
  components: {
    Header,
    AccountSetupContent,
    AccountSetupNavigation
  },
  data() {
    return {
      onboard: null,
      userOnboard: null,
      onboardLength: 0,
      currentOnboardId: 1,
      currentOnboardIndex: 0,
      currentOnboard: {},
      acc_licenses: [],
      user_license_id: null,
      nextBtnDisabled: false,
      locationsTotal: 0,
      workergroupsTotal: 0
    };
  },
  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },
  methods: {
    async initialize() {
      await this.setUserLicenseId();
      await this.getOnboard();
      await this.getUserOnboard();

      //if it is completed but the onboard value is still 0, it sets it to 1
      if (
        this.currentOnboardIndex === this.onboardLength - 1 &&
        !this.isOnboardCompleted
      )
        await this.finalizeOnboard();
    },

    async getOnboard() {
      const response = await OnboardService.getOnboard();

      //get list of onboard
      this.onboard = response.data.data;

      if (this.user_license_id === 1)
        this.onboard = this.onboard.filter(item => item.license_id != 2);
      else if (this.user_license_id === 2)
        this.onboard = this.onboard.filter(item => item.license_id != 1);

      //set onboard length
      this.onboardLength = this.onboard.length;
    },
    async getUserOnboard() {
      const response = await OnboardService.getUserOnboard();

      this.userOnboard = response.data.data;
      if (this.userOnboard[this.userOnboard.length - 1])
        this.currentOnboardId = this.userOnboard[
          this.userOnboard.length - 1
        ].onboard_id;
      this.setCurrentOnboard();
    },
    async setUserLicenseId() {
      //this.dataLicensesLoading = true;
      const myLicenses = await LicenseService.getMyLicenses({});
      this.acc_licenses = myLicenses.data.data;

      //gets first license id from the list of licenses
      if (this.acc_licenses)
        this.user_license_id = this.acc_licenses[0].license_id;
    },
    async nextStep() {
      //set the next step
      this.updateCurrentOnboardId();

      try {
        await OnboardService.postUserOnboard({
          id: this.currentOnboardId
        });
      } catch (e) {
        console.log("error setting user onboard id", e);
      }
      this.setCurrentOnboard();

      if (this.currentOnboardIndex > this.onboardLength - 2) {
        this.finalizeOnboard();
      }
    },
    async backStep() {
      this.currentOnboardId = this.onboard[this.currentOnboardIndex - 1].id;
      this.setCurrentOnboard();
    },
    async setCurrentOnboard() {
      if (this.onboard) {
        this.currentOnboard = this.onboard.filter(
          item => item.id == this.currentOnboardId
        )[0];
        // set current onboard index
        await this.setCurrentOnboardIndex();
        //console.log("3. Set current onboard: ", this.currentOnboard);
        this.setNextBtnDisabled();
      }
    },
    setCurrentOnboardIndex() {
      this.currentOnboardIndex = this.onboard.findIndex(
        item => item.id == this.currentOnboardId
      );
    },
    updateCurrentOnboardId() {
      this.currentOnboardId = this.onboard[this.currentOnboardIndex + 1].id;
    },
    async finalizeOnboard() {
      try {
        //set onboard completed
        this.currentUser.user.onboard = 1;
        await OnboardService.postUserOnboardFinalize();
      } catch (e) {
        console.log("error finalizing user onboard", e);
      }
    },
    async setNextBtnDisabled() {
      //check locations
      if (this.currentOnboardId === 2) {
        await this.setLocationsTotal();

        this.nextBtnDisabled = !this.locationsTotal ? true : false;

        console.log("locationsTotal:", this.locationsTotal);
        console.log("disabled:", this.nextBtnDisabled);
      }
      //check workergroups
      else if (this.currentOnboardId === 3) {
        await this.setWorkergroupsTotal();
        this.nextBtnDisabled = !this.workergroupsTotal ? true : false;
      }
    },
    async setLocationsTotal() {
      const response = await LocationService.find({});
      this.locationsTotal = response.data.data.length;
    },
    async setWorkergroupsTotal() {
      const response = await WorkergroupService.find({});
      this.workergroupsTotal = response.data.data.length;
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isOnboardCompleted() {
      return this.currentUser.user.onboard;
    }
  }
};
</script>
