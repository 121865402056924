<template>
  <div>
    <v-container fluid class="header-inner">
      <v-row>
        <v-col>
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>

          <v-container class="white--text fill-height d-flex align-start">
            <v-row class="d-flex align-center justify-space-between">
              <v-col cols="6">
                <router-link to="/">
                  <v-img
                    v-if="tab === 'lead' || tab === 'asbestos'"
                    alt="WHSAcademy Logo"
                    class="shrink mr-2"
                    contain
                    src="/assets/images/WHSAcademy.svg"
                    transition="scale-transition"
                    width="240"
                    height="70"
                  />
                  <v-img
                    v-if="tab === 'abc'"
                    alt="ABC Logo"
                    class="shrink mr-2"
                    contain
                    src="/assets/images/ABClogo-white.png"
                    transition="scale-transition"
                    width="120"
                    height="102"
                  />
                  <v-img
                    v-if="tab === 'netflix'"
                    alt="Netflix Logo"
                    class="shrink mr-2"
                    contain
                    src="/assets/images/Netflix_Logo.png"
                    transition="scale-transition"
                    width="240"
                    height="70"
                  />
                </router-link>
              </v-col>
              <v-col cols="6">
                <v-spacer></v-spacer>
                <div class="text-xs-right text-right" style="width: 100%">
                  <v-col cols="12">
                    <div v-if="currentUser">
                      <span class="hidden-sm-and-down mr-5"
                        >Welcome back {{ currentUser.user.name }}</span
                      >
                      <!-- <v-btn @click.prevent="logOut" rounded color="secondary" class="ml-3"><v-icon left>mdi-exit-to-app</v-icon> Log out</v-btn> -->
                      <v-app-bar-nav-icon
                        @click.stop="drawer = !drawer"
                        color="white"
                        class="hidden-md-and-up"
                      ></v-app-bar-nav-icon>
                      <template
                        v-if="currentUser && !isAdminEditor && !isUserBasic"
                      >
                        <notification-bell
                          :size="24"
                          class="ml-1"
                          :count="notifCount"
                          :upperLimit="50"
                          style="float:right"
                          counterLocation="upperRight"
                          counterStyle="roundRectangle"
                          counterBackgroundColor="#FF0000"
                          counterTextColor="#FFFFFF"
                          iconColor="#ffffff"
                          @click="notif('new')"
                        />
                      </template>
                      <v-btn
                        v-if="!isUserBasic"
                        small
                        class="mr-1"
                        @click="goToUrl('/notifications')"
                        >Notifications</v-btn
                      >
                    </div>
                  </v-col>
                </div>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="12" sm="5">
                <slot name="title"></slot>
                <slot name="intro"></slot>
              </v-col>

              <v-col cols="12" sm="7" v-if="this.$route.name == 'home'">
                <div
                  class="embed-responsive embed-responsive-16by9"
                  style="width: 100%;"
                >
                  <slot name="video"></slot>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
      style=""
      v-if="currentUser"
      v-model="drawer"
      :color="color"
      :app="$vuetify.breakpoint.mdAndUp"
      :permanent="$vuetify.breakpoint.mdAndUp"
      :expand-on-hover="$vuetify.breakpoint.mdAndUp"
      :temporary="$vuetify.breakpoint.smAndDown"
      :fixed="$vuetify.breakpoint.smAndDown"
      :bottom="$vuetify.breakpoint.smAndDown"
      class="navigation-drawer"
      dark
    >
      <v-list dense nav class="py-0" v-if="!isUserBasic">
        <v-list-item two-line class="px-0">
          <v-list-item-avatar color="primary darken-1">
            <v-icon dark>mdi-account-circle</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ currentUser.user.name }}</v-list-item-title>
            <v-list-item-subtitle v-if="!isAdminEditor">{{
              currentUser.user.organizations.company
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isUserBasic" to="/dashboard">
            <v-list-item-icon>
              <v-icon color="primary">mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserFull" to="/org-dashboard">
            <v-list-item-icon>
              <v-icon color="primary">mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserFull || isUserBasic" to="/mycourses">
            <v-list-item-icon>
              <v-icon color="primary">mdi-school-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Courses</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isUserFull" to="/myquizzes">
            <v-list-item-icon>
              <v-icon color="primary">mdi-format-list-bulleted</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Quizzes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserFull || isAdminEditor" to="/managequestions">
            <v-list-item-icon>
              <v-icon color="primary">mdi-view-list-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Question Bank</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isOrgOwner" to="/manage-locations">
            <v-list-item-icon>
              <v-icon color="primary">mdi-map-marker</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Locations</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isOrgOwner" to="/manage-workergroups">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Worker Groups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isOrgOwner" to="/manage-users">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAdminEditor" to="/managecategories">
            <v-list-item-icon>
              <v-icon color="primary">mdi-format-list-bulleted-type</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Categories</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserFull || isAdminEditor" to="/riskprofiles">
            <v-list-item-icon>
              <v-icon color="primary">mdi-file-percent-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Risk Profiles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isAdminEditor" to="/announcements">
            <v-list-item-icon>
              <v-icon color="primary">mdi-bullhorn-variant-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isUserReviewer" to="/orgreports">
            <v-list-item-icon>
              <v-icon color="primary">mdi-text-box-multiple-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="!isAdminEditor" to="/account">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>My Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!isAdminEditor && !isUserReviewer && !isUserBasic"
            to="/reviewers"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-message-draw</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Reviewers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="!isAdminEditor && !isUserReviewer && !isUserBasic"
            to="/userguidevideo"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-help-box</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>User Guide</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logOut" class="btn-logout">
            <v-list-item-icon>
              <v-icon color="primary">mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <notifications
      group="notifications"
      :duration="-1"
      :width="400"
      animation-name="v-fade-left"
      position="bottom right"
    >
      <template slot="body" slot-scope="props">
        <div class="custom-template">
          <div class="custom-template-icon">
            <i class="icon ion-android-checkmark-circle"></i>
          </div>
          <div class="custom-template-content">
            <div class="custom-template-title">
              {{ props.item.title }}
            </div>
            <div class="custom-template-text" v-html="props.item.text"></div>
          </div>
          <div
            class="custom-template-close"
            @click="triggerClose(props.item.id)"
          >
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import { Role } from "@/helpers/roles";
import NotificationBell from "vue-notification-bell";
import AnnouncementsService from "../services/announcements.service";

export default {
  name: "Header",
  props: {
    title: String,
    intro: String,
    org: String,
    size: String
  },
  data() {
    return {
      menu: false,
      drawer: null,
      color: "turqoise",
      timer: 5,
      infiniteTimer: false,
      showLeftIcn: true,
      showCloseIcn: true,
      count: 0,
      buttonClicked: false,
      showNotifBell: false,
      notifications: [],
      tab: null,
      tabs: {
        "asbestos.whs.academy": "asbestos",
        "lead.whs.academy": "lead",
        "netflix.whs.academy": "netflix",
        "localhost:8080": "abc"
      }
    };
  },
  async created() {
    if (this.currentUser) {
      if (!this.isAdminEditor) {
        await this.setNotifications();
        this.notifications.forEach(item => {
          this.show(
            "custom-template",
            item.title,
            item.message,
            item.body,
            item.id
          );
        });
        this.showNotifBell = true;
      }
      if (
        !this.isAdminEditor &&
        !this.isUserReviewer &&
        !this.currentUser.user.onboard
      ) {
        this.$router.push("/on-boarding");
      }
    }
    //this.tab = this.tabs[window.location.host];
    this.tab = 'abc';
  },
  unmounted() {
    console.log("nav unmounted");
  },
  computed: {
    notifCount() {
      return this.notifications.length;
    },

    currentUser() {
      return this.$store.state.auth.user;
    },
    isOrgOwner() {
      let orgOwner = false;

      this.currentUser.user.roles.forEach(item => {
        if (
          item.role_id === Role.UserFull &&
          this.currentUser.user.id ===
            this.currentUser.user.organizations.user_id
        ) {
          orgOwner = true;
        }
      });
      return orgOwner;
    },
    isUserFull() {
      let userFull = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserFull) {
          userFull = true;
        }
      });
      return userFull;
    },
    // isOrgLocAdmin() {
    //   let orgLocAdmin = false;
    //   this.currentUser.user.roles.forEach(item => {
    //     if (item.role_id === Role.OrgLocAdmin) {
    //       orgLocAdmin = true;
    //     }
    //   });
    //   return orgLocAdmin;
    // },
    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    },
    getConfig: function() {
      return {
        position: this.position,
        timer: this.timer,
        infiniteTimer: this.infiniteTimer,
        showLeftIcn: true,
        showCloseIcn: true
        //title: this.title
      };
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("quizStatus/clearQuiz");
      this.$router.push("/");
      if(this.$route.name === "plan") this.$router.push("/login");
    },

    async setNotifications() {
      const response = await AnnouncementsService.get({});
      this.notifications = response.data.data.slice();
      if (this.notifications.length > 0)
        this.notifications = this.notifications.filter(
          item => item.user_announcements.dismissed === 0
        );
    },

    displayNotification(message) {
      this.$notify(message);
    },

    async triggerClose(id) {
      //remove from the list
      const index = this.notifications.findIndex(item => item.id === id);
      this.notifications.splice(index, 1);

      if (this.$route.name === "notifications") {
        //update list in parent
        this.$emit("dismissClicked", id);
      }

      //set the dissmissed to 1 in the DB
      await AnnouncementsService.remove({
        id
      });
      this.$notify.close(id);
    },

    show(type, title, message, body, id) {
      this.$notify({
        id,
        group: "notifications",
        title: title,
        text: message,
        type,
        data: {
          body: body,
          id: id
        },
        close: function() {
          console.log("id", this.id);
        }
      });
    },
    goToUrl(url) {
      location.href = url;
    }
  },
  components: {
    NotificationBell
  }
};
</script>
<style lang="scss" scoped>
.wrap-text {
  word-wrap: break-word;
}
.notification.n-light {
  margin: 10px;
  margin-bottom: 0;
  border-radius: 3px;
  font-size: 13px;
  padding: 10px 20px;
  color: #495061;
  background: #eaf4fe;
  border: 1px solid #d4e8fd;
  .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 10px;
    color: #2589f3;
  }
}
.custom-template {
  // display: flex;
  // flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  align-items: center;
  justify-content: center;
  &,
  & > div {
    box-sizing: border-box;
  }

  background: #e8f9f0;
  color: #333333;
  border: 2px solid #d0f2e1;
  .custom-template-icon {
    flex: 0 1 auto;
    color: #15c371;
    font-size: 32px;
    padding: 0 10px;
  }
  .custom-template-close {
    position: absolute;
    top: 0px;
    right: -15px;
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

.notifications {
  display: block;
  position: fixed;
  z-index: 5000;
}
.notification-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}
.notification {
  display: block;
  box-sizing: border-box;
  background: #fff;
  text-align: left;
}
.notification-title {
  font-weight: 600;
}
.vue-notification {
  font-size: 12px;
  padding: 10px;
  margin: 0 5px 5px;
  color: #fff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}
.vue-notification.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}
.vue-notification.error {
  background: #e54d42;
  border-left-color: #b82e24;
}
.vue-notification.success {
  background: #68cd86;
  border-left-color: #42a85f;
}
.vn-fade-enter-active,
.vn-fade-leave-active,
.vn-fade-move {
  transition: all 0.5s;
}
.vn-fade-enter,
.vn-fade-leave-to {
  opacity: 0;
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}
.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}
</style>
