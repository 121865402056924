export default function authHeader() {
  //let user = this.$store.state.auth.user;
  let user = JSON.parse(localStorage.getItem("user"));

  // accessToken
  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      "content-type": "application/json",
      accept: "application/json"
    };
  } else {
    // remove all stored sessions and redirect to login
    localStorage.removeItem("vuex");
  }
}
