import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class LicenseService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  sendLicense({ data, query }) {
    return http.post(`grant-license`, data, {
      params: query
    });
  }

  createAccByLicense({ data, query }) {
    return http.post(`signup-granted-license-account`, data, {
      params: query
    });
  }

  revoke({ id }) {
    return http.delete(`revoke-license/${id}`);
  }

  getMyLicenses() {
    return axios.get(`${API_URL}my-owned-licenses`, { headers: authHeader() });
  }

  getInvitationData({ token }) {
    return axios.get(`${API_URL}my-license-invitation/${token}`);
  }
}

export default new LicenseService();
