<template>
  <div>
    <v-alert v-if="apiError" type="error">{{ apiError }}</v-alert>
    <h2 class="h2 mb-8">Available Courses</h2>

    <template v-if="isLoaded">
      <template v-if="courses.length > 0" transition="fade-transition">
        <span v-for="item in courses" :key="item.id" md="4">
          <CourseItem :courses="courses" :item="item" hideActions="true" />
        </span>
      </template>
      <template v-else>
        <v-alert border="top" colored-border type="warning" elevation="5"
          >There are no plans available at this time. Please check back
          soon.</v-alert
        >
      </template>
    </template>
    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading Courses</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>

<script>
import CourseItem from "./CourseItem.vue";
import OrgCoursesService from "../services/orgcourses.service";

export default {
  components: {
    CourseItem
  },
  data: () => ({
    courses: [],
    apiError: "",
    isLoaded: false,
    orgId: 4
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        // const response = await OrgCoursesService.get({
        //   orgId: this.orgId
        // });
        const response = await OrgCoursesService.getCoursesOrg({
          orgId: this.orgId
        });
        this.courses = response.data.slice();
        this.isLoaded = true;
      } catch (e) {
        console.log("error getting courses", e);
      }
    }
  }
};
</script>
<style scoped>
.mybutton {
  text-transform: none !important;
}
</style>
