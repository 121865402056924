<template>
  <div class="login">
    <v-container class="my-8">
      <v-card height="700" class="loginPopup">
        <v-toolbar flat class="loginToolbar">
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="5" class="loginLeft">
                <v-img
                  class="loginImage"
                  src="/assets/images/loginMain.png"
                  width="934"
                />
              </v-col>
              <v-col cols="7" class="loginRight">
                <v-container class="loginForm">
                  <v-row justify="center" align="center"
                    ><v-col cols="9" align="center"
                      ><v-img
                        src="/assets/images/ABClogo.png"
                        width="120"/></v-col
                  ></v-row>
                  <v-row justify="center" align="center"
                    ><v-col cols="9" align="center" class="pb-8 pt-6"
                      ><h1>Sign in to your account</h1></v-col
                    ></v-row
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="8">
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                          id="login_email"
                          v-model="user.email"
                          :rules="emailRules"
                          label="E-mail"
                          background-color="white"
                          required
                          filled
                        ></v-text-field>

                        <v-text-field
                          id="login_password"
                          v-model="user.password"
                          :rules="[v => !!v || 'Password is required']"
                          label="Password"
                          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPass ? 'text' : 'password'"
                          @click:append="showPass = !showPass"
                          background-color="white"
                          required
                          counter
                          filled
                        ></v-text-field>

                        <div>
                          <router-link to="recovery">
                            <p class="fg-pw text-right">
                              Forgot your password?
                            </p>
                          </router-link>
                        </div>
                        <div>
                          <v-btn
                            @submit="handleLogin"
                            width="100%"
                            @click="handleLogin"
                            class="btn-login mt-6"
                            color="secondary"
                            >Login</v-btn
                          >
                        </div>

                        <v-alert v-if="message" type="error" class="mt-5">{{
                          message.message
                        }}</v-alert>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import User from "../models/user";
import { Role } from "@/helpers/roles";

export default {
  name: "Login",
  components: {
  },
  data() {
    return {
      user: new User("", ""),
      valid: true,
      message: "",
      password: "",
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      show: false,

      apiError: ""
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isAdminEditor() {
      let adminEditor = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.AdminEditor) {
          adminEditor = true;
        }
      });
      return adminEditor;
    },
    isUserReviewer() {
      let userReviewer = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserReviewer1) {
          userReviewer = true;
        }
      });
      return userReviewer;
    }
  },
  created() {
    if (this.loggedIn) {
      if (!this.isAdminEditor && !this.isUserReviewer)
        this.$router.push("/plans");
      else if(this.isAdminEditor || this.isUserReviewer)
        this.$router.push("/main");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      const isValid = this.$refs.form.validate();

      if (isValid) {
        if (this.user.email && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            response => {
              this.$store.commit(
                "updateKeepAliveTimerDefault",
                response.session_seconds_left
              );
              if (!this.isAdminEditor && !this.isUserReviewer)
                this.$router.push("/mycourses");
              else if(this.isAdminEditor || this.isUserReviewer)
                this.$router.push("/main");
            },
            error => {
              this.loading = false;
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fg-pw {
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.6);
}
</style>
