import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class WGtypeService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  find({ query }) {
    return http.get(`wgtypes`, {
      params: query
    });
  }

  get({ id, query }) {
    return http.get(`wgtypes/${id}`, {
      params: query
    });
  }

  create({ data, query }) {
    return http.post(`wgtype`, data, {
      params: query
    });
  }

  update({ id, data, query }) {
    return http.put(`wgtype/${id}`, data, {
      params: query
    });
  }

  remove({ id }) {
    return http.delete(`wgtype/${id}`);
  }
}

export default new WGtypeService();
