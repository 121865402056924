<template>
  <div class="dashboard">
    <v-container v-if="!isLoading" class="my-2">
      <v-row>
        <v-col
          v-if="
            hasLicensesAvailable &&
              hasLicensesAvailable != 'full' &&
              licenseForm
          "
          cols="12"
        >
          <v-flex class="text-right">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  dark
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>Send License
                </v-btn>
              </template>
              <span>Tooltip</span>
            </v-tooltip>
          </v-flex>
          <v-dialog v-model="dialog" persistent max-width="500">
            <v-card>
              <v-card-text class="pb-0">
                <v-form ref="form" v-model="validLicenseForm" lazy-validation>
                  <v-card-title class="px-0">
                    <h2 class="text-h3">Send License</h2>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-text-field
                    filled
                    v-model="acc_user.first_name"
                    label="First Name"
                    :rules="[v => !!v || 'First Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="acc_user.last_name"
                    label="Last Name"
                    :rules="[v => !!v || 'Last Name is required']"
                    required
                  ></v-text-field>
                  <v-text-field
                    filled
                    v-model="acc_user.email"
                    label="Email"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <span v-if="showErrorLicense" style="color: #860202">
                    Error sending license, please try again
                  </span>
                </v-form>
              </v-card-text>
              <v-card-actions class="py-0">
                <v-flex class="text-left">
                  <v-btn
                    rounded
                    :id="'acc_send'"
                    class="btn-login ma-3 mb-6"
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-flex>
                <v-flex class="text-right">
                  <v-btn
                    rounded
                    :id="'acc_send'"
                    color="secondary"
                    class="btn-login ma-3 mb-6 px-7"
                    @click="sendLicense()"
                    :disabled="!validLicenseForm"
                  >
                    <template v-if="loadingForm">Loading...</template>
                    <template v-else>Send</template>
                  </v-btn>
                </v-flex>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col v-else cols="12">
          <template v-if="hasLicensesAvailable != 'full'">
            <v-card-title class="px-0">
              <h2 class="text-h3">
                <v-icon class="mr-2" style="color: #00c853; font-size: 24px;"
                  >mdi-check-circle-outline</v-icon
                >License sent
              </h2>
            </v-card-title>
            <v-spacer></v-spacer>
            <p class="body-2">
              Your license was sent. Now it needs to be confirmed by the
              recipient<br />
              Now you have <b>{{ hasLicensesAvailable }}</b> license left.<br />
              <v-btn
                rounded
                :id="'acc_send'"
                color="secondary"
                class="btn-login mt-5"
                @click="sendAnother()"
              >
                Send another license
              </v-btn>
            </p>
          </template>
          <template v-else>
            <v-card-title class="px-0">
              <h2 class="text-h3">All your licenses have been assigned</h2>
            </v-card-title>
          </template>
        </v-col>
      </v-row>
      <v-row v-if="hasLicensesAvailable">
        <v-col cols="12">
          <v-card-title class="px-3">
            <h2 class="text-h3">My Licenses</h2>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-data-table
            :loading="dataLicensesLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            :disable-sort="true"
            :headers="acc_headers"
            :items="acc_licenses"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-5 mb-1 align-center"
            :hide-default-footer="true"
          >
            <template v-slot:item.status="{ item }">
              <p>{{ item.status }}</p>
            </template>

            <template v-slot:item.user.first_name="{ item }">
              <p>{{ updateDashboardUser(item, "first_name") }}</p>
            </template>

            <template v-slot:item.user.last_name="{ item }">
              <p>{{ updateDashboardUser(item, "last_name") }}</p>
            </template>

            <template v-slot:item.user.email="{ item }">
              <p>{{ updateDashboardUser(item, "email") }}</p>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">More info about {{ item.name }}</td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <template v-else>
      <!-- loading -->
      <v-col cols="12">
        <p class="text-center text-overline mb-8">Loading new data</p>
        <v-progress-linear
          color="secondary"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </template>
  </div>
</template>
<script>
// @ is an alias to /src
import LicenseService from "@/services/license.service";

// import MyCourses from "@/components/MyCourses";

export default {
  name: "Generic",
  data() {
    return {
      sendLicenseDialog: false,
      showErrorLicense: false,
      loadingForm: false,
      dialog: false,
      snackbar: false,
      alert: "",
      dataLicensesLoading: true,
      options: {},
      itemsTotal: 0,
      currentPage: 1,
      lastPage: null,
      search: "",
      acc_user: {
        first_name: "",
        last_name: "",
        email: ""
      },
      acc_licenses: [],
      headers: [
        { text: "ID", value: "id", width: "6%" },
        { text: "Plan", value: "courseDetails[0].title" },
        { text: "Date created", value: "created_at" },
        { text: "Status", value: "status" }
      ],
      acc_headers: [
        { text: "First Name", value: "user.first_name", width: "20%" },
        { text: "Last Name", value: "user.last_name", width: "20%" },
        { text: "Email", value: "user.email" },
        { text: "Status", value: "status" }
      ],
      expanded: [],
      singleExpand: true,
      isLoading: false,
      licenseForm: true,
      validLicenseForm: true,
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        // (v) => (v && this.doesUserExist != true) || "Email already exists.",
      ]
    };
  },

  computed: {
    hasLicensesAvailable() {
      if (this.acc_licenses.length) {
        if (this.acc_licenses.find(e => e.status == "inactive")) {
          return this.acc_licenses.filter(e => e.status == "inactive").length;
        } else {
          return "full";
        }
      } else {
        return false;
      }
    },

    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    updateDashboardUser(item, key) {
      if (item && item.user && item.user[key]) {
        return item.user[key];
      } else if (item && item.status == "pending" && item["granted_" + key]) {
        return item["granted_" + key];
      }
    },

    sendAnother() {
      this.acc_user = {
        first_name: "",
        last_name: "",
        email: ""
      };
      this.licenseForm = true;
    },

    async sendLicense() {
      const isValid = this.$refs.form.validate();

      if (isValid) {
        //TODO #372 - Adapt error message to backend response
        this.showErrorLicense = false;
        try {
          this.loadingForm = true;
          await LicenseService.sendLicense({
            data: this.acc_user
          });
          this.licenseForm = false;
          this.loadingForm = false;
          this.getMyLicenses();
        } catch (e) {
          this.loadingForm = false;
          this.showErrorLicense = true;
          console.log("error sending license", e);
        }
      }
    },

    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },
    async initialize() {
      this.getMyLicenses();
    },

    async getMyLicenses() {
      this.dataLicensesLoading = true;
      const myLicenses = await LicenseService.getMyLicenses({});
      this.acc_licenses = myLicenses.data.data;
      this.dataLicensesLoading = false;
    }
  }
};
</script>
