<template>
  <div class="manageusers">
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="team"
            class="elevation-5 align-center"
            :search="search"
            :hide-default-footer="hidePagination"
            :disable-pagination="hidePagination"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <h3>Users</h3>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import TeamService from "@/services/team.service";
import LocationService from "@/services/location.service";
import WorkgroupService from "@/services/workergroup.service";

export default {
  data: () => ({
    dataLoading: true,
    search: "",
    hidePagination: true,
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Email", align: "start", value: "email" },
      { text: "Locations", align: "start", value: "selectedLocationName" },
      {
        text: "Worker Groups",
        align: "start",
        value: "selectedWorkergroupName"
      }
    ],
    team: [],
    org_locations: [],
    org_workergroups: []
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  updated() {},

  mounted() {},

  methods: {
    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    async initialize() {
      // const response = await TeamService.find({});
      const response = await TeamService.getOrgUsers({});
      // const currentUserOrg = this.currentUser.user.organizations;
      const responseWorkgroup = await WorkgroupService.find({});
      const responseLocation = await LocationService.find({});

      this.org_locations = responseLocation.data.data.filter(location => {
        return (
          location.organization_id === this.currentUser.user.organizations.id
        );
      });
      this.org_workergroups = responseWorkgroup.data.data;

      this.team = response.data.organization_users;
      if (this.team.length > 10) this.hidePagination = false;
      this.dataLoading = false;

      // sequential loop so await works
      let team = [];
      for (const [i] of this.team.entries()) {
        if (
          this.team[i].user_locations[0] &&
          this.team[i].user_locations[0].location
        ) {
          this.team[i].selectedLocation = this.team[
            i
          ].user_locations[0].location;
          this.team[i].selectedLocationName = this.team[
            i
          ].user_locations[0].location.name;
        }
        if (
          this.team[i].user_workergroups[0] &&
          this.team[i].user_workergroups[0].workergroup
        ) {
          this.team[i].selectedWorkergroup =
            this.team[i].user_workergroups[0].workergroup || null;
          this.team[i].selectedWorkergroupName = this.team[i]
            .user_workergroups[0].workergroup
            ? this.team[i].user_workergroups[0].workergroup.name
            : null;
        }

        team.push(this.team[i]);
      }
      this.team = team;
    }
  }
};
</script>
