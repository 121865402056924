<template>
  <div>
    <p class="text-body-2">
      <span class="pr-5">
        <v-icon color="gray">mdi-clock-outline</v-icon>
        {{ duration }}
      </span>
      <span class="pr-5">
        <v-icon color="gray">mdi-tag-outline</v-icon>
        {{ subtitle }}
      </span>
    </p>
    <v-divider class="mb-5"></v-divider>
    <h3 class="text-h3 mb-5">{{ currentOnboard.label }}</h3>
    <div>
      <p>
        {{ currentOnboard.description }}
      </p>
    </div>
    <v-card
      v-if="currentOnboardIndex != 0 && currentOnboardIndex != lastOnboardIndex"
    >
      <div
        class="d-flex align-center justify-space-between progress rounded-t pa-5"
      >
        <div class="progress__percent">Progress: {{ progressPercentage }}%</div>
        <div class="progress__bar">
          <v-progress-linear
            :value="progressPercentage"
            rounded
            dark
            color="white"
          ></v-progress-linear>
        </div>
        <div>{{ currentStep }} of {{ onboardLength }}</div>
      </div>
      <v-card-text>
        <v-card>
          <v-card-text>
            <OnboardManageLocations
              v-if="currentOnboardId === 2"
              v-on="$listeners"
            />
            <OnboardManageGroups v-if="currentOnboardId === 3" />
            <OnboardManageUsers v-if="currentOnboardId === 4" />
            <OnboardManageMyLicenses v-if="currentOnboardId === 5" />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-flex class="text-left">
          <v-btn
            v-if="currentOnboardIndex > 0"
            @click="$emit('backStep')"
            rounded
            color="secondary"
            class="ma-5"
            large
          >
            Back
          </v-btn>
        </v-flex>
        <v-flex
          v-if="currentOnboardIndex < lastOnboardIndex"
          class="text-right"
        >
          <v-btn
            @click="$emit('nextStep')"
            rounded
            color="secondary"
            class="ma-5"
            large
            :disabled="nextBtnDisabled == true"
          >
            Next
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>

    <div v-if="currentOnboardIndex === lastOnboardIndex">
      <OnboardLocations />
      <OnboardGroups v-if="user_license_id === 2" />
      <OnboardUsers v-if="user_license_id === 2" />
      <OnboardMyLicenses v-if="user_license_id === 1" />
    </div>

    <div>
      <v-btn
        v-if="currentOnboardIndex === 0"
        rounded
        color="primary"
        dark
        large
        class="mt-3"
        @click="$emit('nextStep')"
      >
        Start Setup
      </v-btn>
      <div  v-else-if="currentOnboardIndex === lastOnboardIndex">
        <v-btn
          @click="$emit('backStep')"
          rounded
          color="secondary"
          class="ma-5"
          large
        >
          Back
        </v-btn>
        <v-btn
          href="/mycourses"
          rounded
          color="primary"
          class="my-5"
          dark
          large
        >
          Go To Courses
        </v-btn>
        
        <v-btn
          href="/userguidevideo"
          rounded
          color="primary"
          class="my-5 mx-3"
          dark
          large
        >
          Watch user guide video
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardManageLocations from "@/components/on-boarding/OnboardManageLocations.vue";
import OnboardManageGroups from "@/components/on-boarding/OnboardManageGroups.vue";
import OnboardManageUsers from "@/components/on-boarding/OnboardManageUsers.vue";
import OnboardManageMyLicenses from "@/components/on-boarding/OnboardManageMyLicenses.vue";
import OnboardLocations from "@/components/on-boarding/summary/OnboardLocations.vue";
import OnboardUsers from "@/components/on-boarding/summary/OnboardUsers.vue";
import OnboardGroups from "@/components/on-boarding/summary/OnboardGroups.vue";
import OnboardMyLicenses from "@/components/on-boarding/summary/OnboardMyLicenses.vue";

export default {
  props: {
    onboard: Array,
    currentOnboardId: Number,
    currentOnboardIndex: Number,
    onboardLength: Number,
    currentOnboard: Object,
    currentUser: Object,
    user_license_id: Number,
    nextBtnDisabled: Boolean
  },
  data() {
    return {
      subtitle: "Awareness & Training",
      duration: "15-20 mins"
    };
  },
  computed: {
    progressPercentage() {
      return Math.round((this.currentStep * 100) / this.onboardLength);
    },
    lastOnboardIndex() {
      return this.onboardLength - 1;
    },
    currentStep() {
      return this.currentOnboardIndex + 1;
    }
  },
  components: {
    OnboardManageLocations,
    OnboardManageGroups,
    OnboardManageUsers,
    OnboardManageMyLicenses,
    OnboardLocations,
    OnboardUsers,
    OnboardGroups,
    OnboardMyLicenses
  }
};
</script>
