import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "/";
let http;

class OrgCoursesService {
  constructor() {
    http = axios.create({
      baseURL: API_URL,
      headers: authHeader()
    });
    http.interceptors.request.use(
      config => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user.token) {
          config.headers.Authorization = "Bearer " + user.token;
        }
        return config;
      },
      error => Promise.reject(error)
    );
  }

  get({ query }) {
    return http.get(`user_organization_courses`, {
      params: query,
      headers: authHeader()
    });
  }

  getCoursesOrg({ orgId }) {
    return axios.get(`${API_URL}organization_custom_courses/${orgId}`, {
      headers: authHeader()
    });
  }
}

export default new OrgCoursesService();
