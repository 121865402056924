<template>
  <div class="managelocations">
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="locations"
            :disable-sort="true"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
            class="elevation-5 align-center"
            :search="search"
            :hide-default-footer="hidePagination"
            :disable-pagination="hidePagination"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      @click="dialog = true"
                      rounded
                    >
                      <v-icon left>mdi-plus</v-icon> Create New Location
                    </v-btn>
                  </template>
                  <span>Tooltip</span>
                </v-tooltip>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <h3 class="text-h4">{{ formTitle }}</h3>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-text-field
                                filled
                                v-model="editedItem.name"
                                label="Location Name"
                                :rules="[v => !!v || 'Name is required']"
                                required
                              ></v-text-field>
                              <v-text-field
                                filled
                                v-model="editedItem.address"
                                label="Address"
                              ></v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="save(editedItem)"
                        >Save Location</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" persistent max-width="500px">
                  <v-card class="pa-6 text-center">
                    <h3 class="headline mb-3">
                      Are you sure you want to delete this location?
                    </h3>
                    <p>
                      Any worker groups tied to this location will also be
                      deleted.
                    </p>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        rounded
                        text
                        @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary"
                        dark
                        rounded
                        @click="deleteItemConfirm"
                        >Confirm</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <div class="action-icons">
                <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                <v-icon v-if="!item.default" @click="deleteItem(item)">mdi-delete</v-icon>
              </div>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import LocationService from "@/services/location.service";

export default {
  data: () => ({
    snackbar: false,
    alert: "",
    dialog: false,
    dialogDelete: false,
    valid: true,
    dataLoading: true,
    options: {},
    itemsTotal: 0,
    currentPage: 1,
    lastPage: null,
    search: "",
    headers: [
      { text: "Location Name", align: "start", value: "name" },
      { text: "Location Address", align: "start", value: "address" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],
    locations: [],
    hidePagination: true,
    editedIndex: -1,
    editedItem: {
      name: "",
      address: ""
    },
    defaultItem: {
      name: "",
      address: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create New Location" : "Edit Location";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedLocations();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    async initialize() {
      this.getPagedLocations();
    },

    async getPagedLocations() {
      this.dataLoading = true;

      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await LocationService.find({
        query: {
          page: page
        }
      });

      this.locations = response.data.data;
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      if (itemsPerPage > 0) {
        this.locations = this.locations.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      //set location default label
      this.locations.forEach(function(obj) {
        obj.default = false;
      });

      this.locations[0].default = true;

      if (this.locations.length > 10) this.hidePagination = false;

      this.dataLoading = false;
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    editItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.locations.splice(this.editedIndex, 1);
      await LocationService.remove({
        id: this.editedItem.id
      });
      this.closeDelete();
      this.itemsTotal--;
      //update next button status
      this.$emit("setNextBtnDisabled");
      this.showSnackbar(this.editedItem.name + " has been deleted");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            name: item.name,
            address: item.address
          };

          const locationId = this.locations[this.editedIndex].id;
          await LocationService.update({
            id: locationId,
            data: data
          });

          Object.assign(this.locations[this.editedIndex], this.editedItem);
          this.showSnackbar(item.name + " has been updated");
        } else {
          // create item
          const data = {
            organization_id: this.currentUser.user.organizations.id,
            name: item.name,
            address: item.address
          };

          const response = await LocationService.create({
            data: data
          });

          this.locations.push(response.data);
          this.itemsTotal++;
          this.showSnackbar(item.name + " has been added");
          //update next button status
          this.$emit("setNextBtnDisabled");
        }
        this.close();
      }
    }
  }
};
</script>
<style>
.action-icons {
  width: 100px !important;
}
</style>
