<template>
  <div class="managelocations">
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="locations"
            :disable-sort="true"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
            class="elevation-5 align-center"
            :search="search"
            :hide-default-footer="hidePagination"
            :disable-pagination="hidePagination"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <h3>My Locations</h3>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import LocationService from "@/services/location.service";

export default {
  data: () => ({
    dataLoading: true,
    options: {},
    itemsTotal: 0,
    currentPage: 1,
    lastPage: null,
    search: "",
    headers: [
      { text: "Location Name", align: "start", value: "name" },
      { text: "Location Address", align: "start", value: "address" }
    ],
    locations: [],
    hidePagination: true
  }),

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedLocations();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    async initialize() {
      this.getPagedLocations();
    },

    async getPagedLocations() {
      this.dataLoading = true;

      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const { page, itemsPerPage } = this.options;

      const response = await LocationService.find({
        query: {
          page: page
        }
      });

      this.locations = response.data.data;
      this.itemsTotal = response.data.meta.total;
      this.lastPage = response.data.meta.last_page;

      if (itemsPerPage > 0) {
        this.locations = this.locations.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      //set location default label
      this.locations.forEach(function(obj) {
        obj.default = false;
      });

      this.locations[0].default = true;

      if (this.locations.length > 10) this.hidePagination = false;

      this.dataLoading = false;
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    }
  }
};
</script>
