<template>
  <v-card
    class="mb-5 p-0 courses-horizontal"
    elevation="0"
    outlined
    style="overflow: hiddend"
  >
    <v-row>
      <v-col cols="4" style="">
        <v-img
          class="rounded-br-xl card-image"
          height="312"
          style="background-size: 100% 100%;"
          width="362"
          :src="item.full_image"
        >
        </v-img>
      </v-col>
      <v-col cols="8">
        <v-card-title
          ><h5 class="h5 pt-0 pb-2">{{ item.title }}</h5></v-card-title
        >
        <v-card-subtitle>
          <p class="text-body-2">
            <span class="pr-5">
              <v-icon color="primary">mdi-clock-outline</v-icon>
              {{ timeConvert(item.duration) }}
            </span>
            <span class="pr-5">
              <v-icon color="primary">mdi-tag-outline</v-icon>
              {{ item.category.name }}
            </span>
          </p>
        </v-card-subtitle>
        <v-card-text>
          <div v-html="truncateString(item.summary, 250)"></div>
        </v-card-text>
        <v-card-actions>
          <template v-if="hideActions !== 'true'">
            <v-btn :to="`/plans/${item.id}`" color="secondary" class="mr-3"
              >Enter course</v-btn
            >
            <v-btn :to="`/quizsingle/${item.attached_quizzes[0]}`" color="primary"
              >Launch Quiz</v-btn
            >
          </template>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: "Response",
  props: ["item", "hideActions"],
  methods: {
    timeConvert(n) {
      var num = n;
      var hours = num / 60;
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      if (rminutes > 1 && rhours > 1) {
        return rhours + " hours and " + rminutes + " minutes";
      } else if (rminutes > 1 && rhours === 0) {
        return rminutes + " minutes";
      } else {
        return rhours + " hours";
      }
    },
    truncateString(str, num) {
      // If the length of str is less than or equal to num
      // just return str--don't truncate it.
      if (str.length <= num) {
        return str;
      }
      // Return str truncated with '...' concatenated to the end of str.
      return str.slice(0, num) + "...";
    }
  }
};
</script>
<style scoped>
.mybutton {
  text-transform: none !important;
}
</style>
