import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "/login", {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    return axios
      .get(API_URL + `/logout`, {
        headers: authHeader()
      })
      .then(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("vuex");
      });
  }

  keepAlive() {
    return axios.get(API_URL + `/keep-alive`, {
      headers: authHeader()
    });
  }
}

export default new AuthService();
