var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[(!_vm.isLoading)?_c('v-container',{staticClass:"my-2"},[(_vm.hasLicensesAvailable)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-5 mb-1 align-center",attrs:{"loading":_vm.dataLicensesLoading,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":true,"headers":_vm.acc_headers,"items":_vm.acc_licenses,"sort-desc":[false, true],"multi-sort":"","hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"grey lighten-2"}},[_c('h3',[_vm._v("My Licenses")])])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.status))])]}},{key:"item.user.first_name",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.updateDashboardUser(item, "first_name")))])]}},{key:"item.user.last_name",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.updateDashboardUser(item, "last_name")))])]}},{key:"item.user.email",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.updateDashboardUser(item, "email")))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("More info about "+_vm._s(item.name))])]}}],null,false,902886337)})],1)],1):_vm._e()],1):[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text-overline mb-8"},[_vm._v("Loading new data")]),_c('v-progress-linear',{attrs:{"color":"secondary","indeterminate":"","rounded":"","height":"6"}})],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }