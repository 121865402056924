import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
import { quizStatus } from "./quiz.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    keepAliveTimer: 600,
    keepAliveTimerDefault: 600,
    keepAlive: false,
    firstQuizStatus: false
  },
  mutations: {
    updateKeepAlive(state, status) {
      state.keepAlive = status;
    },
    updateKeepAliveTimer(state, status) {
      state.keepAliveTimer = status;
    },
    updateKeepAliveTimerDefault(state, status) {
      state.keepAliveTimerDefault = status;
    },
    changeFirstQuizStatus(state, status) {
      state.firstQuizStatus = status;
    }
  },
  actions: {},
  modules: {
    auth,
    quizStatus
  },
  plugins: [createPersistedState()]
});
