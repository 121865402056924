<template>
  <div class="manageusers">
    <v-container class="my-2">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="team"
            class="elevation-5 align-center"
            :search="search"
            :hide-default-footer="hidePagination"
            :disable-pagination="hidePagination"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      dark
                      rounded
                      v-bind="attrs"
                      v-on="on"
                      @click="dialog = true"
                    >
                      <v-icon left>mdi-plus</v-icon>Create New User
                    </v-btn>
                  </template>
                  <span>Tooltip</span>
                </v-tooltip>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <h3 class="text-h4">{{ formTitle }}</h3>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-text-field
                                filled
                                v-model="editedItem.name"
                                label="Name"
                                :rules="[v => !!v || 'Name is required']"
                                required
                              ></v-text-field>
                              <v-text-field
                                filled
                                v-model="editedItem.email"
                                label="Email"
                                :rules="[v => !!v || 'Email is required']"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-if="editedIndex === -1"
                                filled
                                v-model="editedItem.password"
                                label="Password"
                                :rules="[v => !!v || 'Password is required']"
                                required
                              ></v-text-field>
                              <v-select
                                filled
                                v-model="editedItem.selectedLocation"
                                :items="org_locations"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select Location"
                                :rules="[v => !!v || 'Location is required']"
                                return-object
                              ></v-select>
                              <v-select
                                filled
                                v-model="editedItem.selectedWorkergroup"
                                :items="org_workergroups"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select Workergroup"
                                :rules="[v => !!v || 'Workergroup is required']"
                                return-object
                              ></v-select>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="save(editedItem)"
                        >Save User</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <!--              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>-->
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import _ from "lodash";
import TeamService from "@/services/team.service";
import TeamWorkergroupService from "@/services/teamworkergroup.service";
import TeamLocationService from "@/services/teamlocation.service";
import TeamOrganizationService from "@/services/teamorganization.service";
import LocationService from "@/services/location.service";
import WorkgroupService from "@/services/workergroup.service";

export default {
  data: () => ({
    dataLoading: true,
    snackbar: false,
    alert: "",
    dialog: false,
    valid: true,
    search: "",
    hidePagination: true,
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Email", align: "start", value: "email" },
      { text: "Locations", align: "start", value: "selectedLocationName" },
      {
        text: "Worker Groups",
        align: "start",
        value: "selectedWorkergroupName"
      },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],
    team: [],
    org_locations: [],
    org_workergroups: [],
    editedIndex: -1,
    originalItem: {
      name: "",
      email: "",
      password: "",
      selectedLocation: null,
      selectedWorkergroup: null
    },
    editedItem: {
      name: "",
      email: "",
      password: "",
      selectedLocation: null,
      selectedWorkergroup: null
    },
    defaultItem: {
      name: "",
      email: "",
      password: "",
      selectedLocation: null,
      selectedWorkergroup: null
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Create New User" : "Edit User";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  updated() {},

  mounted() {},

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    async initialize() {
      // const response = await TeamService.find({});
      const response = await TeamService.getOrgUsers({});
      // const currentUserOrg = this.currentUser.user.organizations;
      const responseWorkgroup = await WorkgroupService.find({});
      const responseLocation = await LocationService.find({});

      this.org_locations = responseLocation.data.data.filter(location => {
        return (
          location.organization_id === this.currentUser.user.organizations.id
        );
      });
      this.org_workergroups = responseWorkgroup.data.data;

      this.team = response.data.organization_users;
      if (this.team.length > 10) this.hidePagination = false;
      this.dataLoading = false;

      // this.team = response.data.data.filter(myTeam => {
      //   if (myTeam.organizations && myTeam.organizations.length > 0) {
      //     if (myTeam.roles && myTeam.roles.length > 0) {
      //       return (
      //           myTeam.organizations[0].id === currentUserOrg.id &&
      //           myTeam.roles[0].name === "WGUser"
      //       );
      //     }
      //   }
      // this.dataLoading = false;
      // });

      // sequential loop so await works
      let team = [];
      for (const [i] of this.team.entries()) {
        if (
          this.team[i].user_locations[0] &&
          this.team[i].user_locations[0].location
        ) {
          this.team[i].selectedLocation = this.team[
            i
          ].user_locations[0].location;
          this.team[i].selectedLocationName = this.team[
            i
          ].user_locations[0].location.name;
        }
        if (
          this.team[i].user_workergroups[0] &&
          this.team[i].user_workergroups[0].workergroup
        ) {
          this.team[i].selectedWorkergroup =
            this.team[i].user_workergroups[0].workergroup || null;
          this.team[i].selectedWorkergroupName = this.team[i]
            .user_workergroups[0].workergroup
            ? this.team[i].user_workergroups[0].workergroup.name
            : null;
        }

        // const responseTeamLocation = await TeamLocationService.get({
        //   user_id: person.id
        // });
        //
        // if (responseTeamLocation.data[0]) {
        //   this.team[i].selectedLocation = responseTeamLocation.data[0];
        //   this.team[i].selectedLocationName = responseTeamLocation.data[0].name;
        // }
        //
        // const responseTeamWorkergroup = await TeamWorkergroupService.get({
        //   user_id: person.id
        // });
        // this.team[i].selectedWorkergroup =
        //     responseTeamWorkergroup.data[0] || null;
        // this.team[i].selectedWorkergroupName = responseTeamWorkergroup.data[0]
        //     ? responseTeamWorkergroup.data[0].name
        //     : null;

        team.push(this.team[i]);
      }
      this.team = team;
    },

    editItem(item) {
      this.originalItem = Object.assign({}, item);
      this.editedIndex = this.team.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.selectedLocation) {
        this.editedItem.selectedLocation = this.org_locations.filter(value => {
          return value.id === item.selectedLocation.id;
        })[0];
      }

      if (this.editedItem.selectedWorkergroup) {
        this.editedItem.selectedWorkergroup = this.org_workergroups.filter(
          value => {
            return value.id === item.selectedWorkergroup.id;
          }
        )[0];
      }

      this.dialog = true;
    },

    async deleteItem(person) {
      const index = this.team.indexOf(person);
      confirm("Are you sure you want to delete this user?") &&
        this.team.splice(index, 1);

      await TeamService.remove({
        id: person.id
      });

      if (_.get(person, "selectedLocation.id")) {
        this.deleteTeamLocation(person.id, person.selectedLocation.id);
      }
      if (_.get(person, "selectedWorkergroup.id")) {
        this.deleteTeamWorkergroup(person.id, person.selectedWorkergroup.id);
      }

      this.showSnackbar(person.name + " has been deleted");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    hydrateLocation() {
      let team = [];
      this.team.forEach(member => {
        if (member.selectedLocation) {
          member.selectedLocationName = member.selectedLocation.name;
        }

        team.push(Object.assign({}, member));
      });
      this.team = team;
    },

    hydrateWorkergroup() {
      let team = [];
      this.team.forEach(member => {
        if (member.selectedWorkergroup) {
          member.selectedWorkergroupName = member.selectedWorkergroup.name;
        }
        team.push(Object.assign({}, member));
      });
      this.team = team;
    },

    async updateTeamWorkergroup(userId, oldWorkergroupId, newWorkergroupId) {
      const data = {
        user_id: userId,
        workergroup_id: newWorkergroupId
      };

      if (oldWorkergroupId) {
        await TeamWorkergroupService.remove({
          user_id: userId,
          workergroup_id: oldWorkergroupId
        });
      }

      await TeamWorkergroupService.create({
        data: data
      });
    },

    async deleteTeamLocation(userId, locationId) {
      return await TeamLocationService.remove({
        user_id: userId,
        location_id: locationId
      });
    },

    async deleteTeamWorkergroup(userId, workergroupId) {
      return await TeamWorkergroupService.remove({
        user_id: userId,
        workergroup_id: workergroupId
      });
    },

    async updateTeamLocation(userId, oldLocationId, newLocationId) {
      const data = {
        user_id: userId,
        location_id: newLocationId
      };

      if (oldLocationId) {
        await TeamLocationService.remove({
          user_id: userId,
          location_id: oldLocationId
        });
      }

      await TeamLocationService.create({
        data: data
      });
    },

    async save(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          // update item
          const data = {
            name: item.name,
            email: item.email,
            password: item.password
          };

          const updatedUser = await TeamService.update({
            id: item.id,
            data: data
          });

          const userId = updatedUser.data.id;
          const oldLocationId = this.originalItem.selectedLocation
            ? this.originalItem.selectedLocation.id
            : null;
          const newLocationId = this.editedItem.selectedLocation
            ? this.editedItem.selectedLocation.id
            : null;
          if (
            this.editedItem.selectedLocation !==
            this.originalItem.selectedLocation
          ) {
            this.updateTeamLocation(userId, oldLocationId, newLocationId);
          }

          const oldWorkergroupId = this.originalItem.selectedWorkergroup
            ? this.originalItem.selectedWorkergroup.id
            : null;
          const newWorkergroupId = this.editedItem.selectedWorkergroup
            ? this.editedItem.selectedWorkergroup.id
            : null;
          if (
            this.editedItem.selectedWorkergroup !==
            this.originalItem.selectedWorkergroup
          ) {
            this.updateTeamWorkergroup(
              userId,
              oldWorkergroupId,
              newWorkergroupId
            );
          }

          this.team[this.editedIndex] = this.editedItem;

          this.hydrateWorkergroup();
          this.hydrateLocation();

          this.showSnackbar(item.name + " has been updated");
        } else {
          // create item
          const orgId = this.getOrgId();

          const data = {
            name: item.name,
            email: item.email,
            password: item.password,
            roles: [
              {
                role_id: process.env.VUE_APP_API_WGUSER_ID
              }
            ]
          };

          const newUser = await TeamService.create({
            data: data
          });

          if (newUser.data) {
            const orgData = {
              user_id: newUser.data.id,
              organization_id: orgId
            };
            // add to org
            await TeamOrganizationService.create({
              data: orgData
            });
          }

          this.team.push(newUser.data);

          const userId = newUser.data.id;
          const newLocationId = item.selectedLocation
            ? item.selectedLocation.id
            : null;
          if (newLocationId) {
            this.updateTeamLocation(userId, null, newLocationId);
          }

          const newWorkergroupId = item.selectedWorkergroup
            ? item.selectedWorkergroup.id
            : null;
          if (newWorkergroupId) {
            this.updateTeamWorkergroup(userId, null, newWorkergroupId);
          }

          const lastI = this.team.length - 1;

          this.team[lastI].selectedLocation = item.selectedLocation;
          this.team[lastI].selectedLocationName = item.selectedLocation.name;

          this.team[lastI].selectedWorkergroup = item.selectedWorkergroup;
          this.team[lastI].selectedWorkergroupName = item.selectedWorkergroup.name;

          this.showSnackbar(item.name + " has been created");
        }
        this.close();
      }
    }
  }
};
</script>
